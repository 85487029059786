﻿@body-font: 'Prompt', Arial, sans-serif;
@heading-font: 'Montserrat', Arial, sans-serif;

@max-content-width: 1600px;

@primary-color: #005972;
@alt-color: #433D3C;
@neutral-color: #c9d4cb;

@link-color: #476c29;
@link-hover-color: @alt-color;
@alt-link-color: #005ed4;

@heading-color-primary: @primary-color;
@heading-color-secondary: @alt-color;

@header-height: 85px;
@header-image-height: 65px;
@header-bg: @primary-color;
@header-border-bottom: 2px solid white;

@account-bar-menu-open-bg-color: #FFF;
@account-bar-menu-open-color: black;
@account-bar-button-color: #FFF;
@account-bar-button-bg-color: inherit;

@navbar-breaking-point: 875px;
@navbar-color: white;
@navbar-hover-color: white;
@navbar-active-color: white;
@navbar-bg-color: @primary-color;
@navbar-active-bg-color: @primary-color;
@navbar-border-bottom: 6px solid @primary-color;
@navbar-border-bottom-active-color: white;
@navbar-collapse-color: white;
@navbar-collapse-hover-color: white;
@navbar-collapse-active-color: white;
@navbar-collapse-bg-color: @primary-color;
@login-link-color: white;

@btn-primary-color: #4d4d4d;
@btn-primary-bg: #ffc924;
@btn-primary-border: #939393;

@btn-default-color: #4d4d4d;
@btn-default-bg: #cccccc;
@btn-default-border: #939393;

@table-header-bg: #4d4d4d;
@table-active-bg: #d9edf7;
@table-alt-bg: #fff;
@table-td-border: #979797;
@table-hover-bg: #f5f5f5;
@table-cell-padding: 4px;

@data-table-empty-color: #ffc924;
@data-table-empty-font-color: #4d4d4d;

@avatar-background-color: white;
@avatar-border-color: @primary-color;
@avatar-initials-color: @primary-color;

@username-color: @alt-color;

@helpful-links-color: white;
@helpful-links-bg-color: @primary-color;

@footer-height: 140px;
@footer-links-color: #fff;
@footer-background-color: @primary-color;

@breadcrumb-bg: #fff;
@breadcrumb-link: #005ed4;

@h1-color: #00467f;
@h2-color: #476c29;
@h3-color: #00467f;
@label-color: #00467f;

@dialog-title-bar-color: #476c29;
@dialog-title-bar-font-color: #FFF;

@writing-prompt-list-item: #005ed4;

@login-footer-color: #FFF;

@input-border-color: #cccccc;

@datepicker-bg: @primary-color;

@import "_global_rebrand";

/************************* SITE CUSTOMIZATIONS *************************/
