﻿/// <reference path="miwrite.less" />
/// <reference path="mitutor.less" />
/// <reference path="erbwriting.less" / >
/// <reference path="utahcompose.less" />

/***************************** FONTS *************************************/

* {
    font-family: @body-font;
}

h1, h2, h3, h4, h5, h6, label, .ui-dialog-title {
    font-family: @heading-font;
}

/***************************** LINKS *************************************/

a {
    color: @link-color;
}

a:hover {
    color: @link-hover-color;
}

/**************************** HEADINGS ***********************************/

h1 {
    color: @h1-color;
}

h2 {
    color: @h2-color;
}

h3 {
    color: @h3-color;
}

label {
    color: @label-color;
    font-weight: 600;
    font-size: 14px;
}

legend {
    color: @heading-color-secondary;
}

/**************************** FOOTER ************************************/

.login-footer {
    margin-bottom: 0px !important;
    padding-bottom: 10px;
    padding-top: 5px;
    color: @login-footer-color;
}

.login-footer > a {
    color: @login-footer-color;
}

/**************************** BUTTONS *************************************/

.button-variant(@color, @background, @border) {
    background-color: @background;
    color: @color;
    border-color: @border;

    &:hover,
    &:active,
    &:focus,
    &:disabled:hover {
        background-color: darken(@background, 10%);
        border-color: darken(@border, 10%);
        color: @color;
    }

    &:disabled {
        background-color: lighten(@background, 5%);
        border-color: @border;
        color: @color;
    }
}

.btn-primary, .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    .button-variant(@btn-primary-color, @btn-primary-bg, @btn-primary-border);
}

.btn-default {
    .button-variant(@btn-default-color, @btn-default-bg, @btn-default-border);
}

.btn-default:hover {
    background-color: darken(@btn-default-bg, 10%);
    border-color: darken(@btn-default-border, 10%);
    color: @btn-default-color;
}

.btn-important {
    .button-variant(@btn-primary-color, @btn-primary-bg, @btn-primary-border);
}

.btn-confirm {
    .button-variant(@btn-primary-color, @btn-primary-bg, @btn-primary-border);
    border: solid 1px !important;
    color: @btn-primary-color !important;
}

.btn-cancel {
    .button-variant(@btn-default-color, @btn-default-bg, @btn-default-border);
    border: solid 1px !important;
    color: @btn-default-color !important;
}

/*************************** TABLES ***************************************/

.table > thead > tr > th {
    background-color: @table-header-bg !important;
    color: white;
    vertical-align: middle;
}

.table-striped > tbody > tr:nth-of-type(odd),
.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
    background-color: @table-alt-bg;
}

.table td.group-row {
    background-color: @table-alt-bg;
}

.dataTable td.dataTables_empty,
.table.table-no-data tbody > tr > td:first-child,
.table tbody > tr > td.table_empty {
    background-color: @data-table-empty-color;
    color: @data-table-empty-font-color;
    text-align: center;
    font-style: italic;
}

.table.table-no-data thead > tr > th {
    border-bottom: none;
}

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
    background-color: @table-active-bg /* Active table row */
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    padding: @table-cell-padding;
}

.table > tbody > tr > td {
    border-top-color: @table-td-border;
}

.table > tbody > tr:hover,
.table-hover > tbody > tr:hover,
.table-striped > tbody > tr:nth-of-type(odd):hover {
    background: @table-hover-bg;
}

.table > thead > tr > th.row-selector,
.table > tbody > tr > td.row-selector {
    width: 0;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
}

.dataTables_paginate .btn.current { /* Selected page button */
    background-color: @btn-primary-bg;
    border-color: @btn-primary-border;
    color: @btn-primary-color;
}


/********************************** BACKGROUND **************************************/

html {
    background-color: white;
    position: relative;
}

body {
    background: white;
}

/*********************************** HEADER ****************************************/

#header {
    width: 100%;
    height: @header-height;
    background-color: @header-bg;
    border-bottom: @header-border-bottom;

    & > .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: @header-height;
        padding: 0 20px;
    }

    .header-image {
        height: @header-image-height;
    }
}

.login-page #header {
    border-bottom: none;

    & > .wrapper {
        justify-content: center;
    }
}

/************************************ BODY ***************************************/

#body > .wrapper {
    border-color: lightgray;
    border-width: thin;
    border-style: none solid solid solid;
}

/************************************ NAVBAR OVERRIDES TABS ***********************************/

nav .popover {
    min-width: 365px;
}

.navbar {
    @media (min-width: 768px) and (max-width: @navbar-breaking-point) {
        .navbar-nav {
            margin: 7.5px -15px !important;
        }

        .navbar-text {
            margin: 7px 0px !important;
        }
    }

    @media (max-width: @navbar-breaking-point) {
        .navbar-header {
            float: none;
        }

        .navbar-left {
            float: none !important;
        }

        .navbar-right {
            float: none !important;
        }

        .navbar-fixed-top {
            top: 0;
            border-width: 0 0 1px;
        }

        .navbar-toggle {
            display: block;
        }

        .navbar-collapse.in {
            overflow-y: auto !important;
        }

        .navbar-collapse {
            border-top: 1px solid transparent;
            box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
        }

        .navbar-collapse.collapse {
            display: none !important;
        }

        .collapse.in {
            display: block !important;
        }

        .navbar-nav {
            float: none !important;
            margin-top: 7.5px;
        }

        .navbar-nav > li {
            float: none;
        }

        .navbar-nav > li > a {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .navbar-nav .open .dropdown-menu {
            position: static;
            float: none;
            width: auto;
            margin-top: 0;
            background-color: transparent;
            border: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .navbar-nav .open .dropdown-menu .dropdown-header,
        .navbar-nav .open .dropdown-menu > li > a {
            padding: 5px 15px 5px 25px;
        }
    }
}

.navbar-default {
    background-color: @navbar-bg-color;
    min-height: 40px;
    border: 0;
    border-radius: 0;
    margin-bottom: 0px;

    .navbar-text {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .navbar-nav > li {
        border-bottom: @navbar-border-bottom;

        a {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            color: @navbar-color;
            background-color: @navbar-bg-color;

            &:focus,
            &:hover {
                color: @navbar-hover-color;
            }
        }

        &.active {
            border-bottom-color: @navbar-border-bottom-active-color;

            a {
                color: @navbar-active-color;
                background-color: @navbar-active-bg-color;

                &:focus,
                &:hover {
                    color: @navbar-active-color;
                    background-color: @navbar-active-bg-color;
                }
            }
        }
    }

    .navbar-nav .open .dropdown-menu > li > a:focus,
    .navbar-nav .open .dropdown-menu > li > a:hover {
        color: #333;
        background-color: transparent;
    }

    .navbar-toggle:not(:focus) .icon-bar,
    .navbar-toggle:not(:hover) .icon-bar {
        background-color: @navbar-color;
    }

    .navbar-right > .open > a,
    .navbar-right > .open > a:focus,
    .navbar-right > .open > a:hover {
        background-color: @account-bar-menu-open-bg-color;
        color: @account-bar-menu-open-color;
    }

    a#login-link {
        color: @login-link-color;
    }

    .mi-write-menu-icon {
        height: 18px;
        width: 18px;
    }

    @media (max-width: @navbar-breaking-point) {
        .navbar-static-menu {
            position: absolute;
            top: 50px;
            min-width: 150px;
            right: 0;
            z-index: 5;
            background-color: @navbar-collapse-bg-color;
            border: solid 1px #ddd;

            .navbar-nav {
                margin-bottom: 0;
            }

            li {
                border-bottom-color: @navbar-collapse-bg-color;
            }

            li a {
                color: @navbar-collapse-color;
                background-color: @navbar-collapse-bg-color;

                &:hover {
                    color: @navbar-collapse-hover-color;
                }
            }

            li.active a {
                color: @navbar-collapse-active-color;
                background-color: @navbar-collapse-bg-color;

                &:hover {
                    color: @navbar-collapse-active-color;
                }
            }
        }

        .navbar-nav .open .dropdown-menu > li > a {
            color: #FFF;
        }

        .navbar-text {
            color: #FFF;
        }
    }
}

/************************************ ACCOUNT BAR *************************************/

ul#account-bar {
    display: flex;

    > li {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
        margin-left: 15px;
        color: @username-color;
    }

    > li button {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 50%;

        i {
            padding: 8.5px;
        }
    }

    > li .notification-badge {
        display: inline-block;
        position: absolute;
        left: 26px;
        top: 0;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        font-size: 11px;
        line-height: 17px;
        text-align: center;
        color: white;
        background-color: red;
        pointer-events: none;
    }

    > li .notification-badge.tiny-text {
        font-size: 9px;
    }

    #account-menu-button {
        background: transparent;

        #account-menu-avatar {
            padding: 0;
        }

        .account-avatar-initials {
            background: @avatar-background-color;
            color: @avatar-initials-color;
        }
    }

    .dropdown-menu > li > p {
        margin: 0;
        padding: 3px 20px;
        cursor: default;
    }

    .dropdown-menu > li.divider {
        margin: 4px 0;
    }

    .account-menu-wrapper {
    }

    .account-bar-button {
        button {
            background: @account-bar-button-bg-color;
            border-radius: 50%;
            border: none;
            color: @account-bar-button-color;
        }
    }
}

/************************************ AVATAR *************************************/

.account-avatar img,
.account-avatar-initials {
    border: 2px solid @avatar-border-color;
    width: 40px;
    height: 40px;
}

.account-avatar-initials {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background: @avatar-border-color;
    border-radius: 50%;
    color: white;
}

.account-avatar-container {
    display: inline-block;
    position: relative;
}

.account-avatar .avatar-remove-btn,
.account-avatar-initials .avatar-remove-btn {
    position: absolute;
    bottom: -5px;
    right: -5px;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
}

.account-avatar-initials .avatar-remove-btn i {
    font-size: 15px;
    top: -2px;
}

/********************************** HELPFUL LINKS ********************************/

#helpful-links-wrapper,
#helpful-links {
    background-color: @helpful-links-bg-color;
}

#helpful-links {
    padding: 20px 0px;
    border-style: none;
}

#helpful-links li a {
    color: @helpful-links-color;
}

/********************************* BREADCRUMBS ************************************/

#breadcrumbs {
    margin-left: 6px;

    .breadcrumb {
        background-color: @breadcrumb-bg;
        padding: 8px 0;
    }

    hr {
        margin: 0;
    }

    a.breadcrumb,
    a.dropdown-toggle {
        color: @breadcrumb-link;

        &:hover {
            color: @link-hover-color;
        }
    }
}

/*********************************** MESSAGES ****************************************/

.miwrite-messages li.current-user-message .text {
    background-color: @primary-color;
    color: white;
}

/***********************************CONTENT WRAPPER *************************************/

.content-wrapper {
    max-width: @max-content-width;
}

/***********************************STICKY FOOTER *************************************/

@media not print {
    #footer {
        background-color: @footer-background-color;
    }

    #footer-container {
        display: flex;
        flex-direction: row;
    }

    #footer-links-container {
        width: 100%;
    }

    #footer-bottom {
        padding: 10px 15px;
    }

    .navbar-nav li a:focus,
    .navbar-nav li button:focus {
        outline: none;
        outline-offset: -2px;
        outline-style: auto;
        outline-width: 1px;
    }
}

/******************************* EXTERNAL LOGINS ******************************/

.external-container .logo-container {
    display: inline-block;
}

.btn.external-logins-button {
    width: 100%; /*calc(~'100% * (1/2) - 10px');*/
    text-align: left;
    margin: 5px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.24), 0 0 2px 0 rgba(0,0,0,.12);
    border-radius: 2px;
    font-size: 14px;
    font-weight: 700;
}

.Google-button {
    background-color: white;
    color: #737373;
}

.Google-logo {
    background-image: url('../../css/images/externallogins/btn_google_light_normal_ios.svg');
    background-position: -10px -10px;
    padding: 5px 35px 5px 0;
}

/******************************* DIALOG **************************************/

.ui-widget-header {
    background: @dialog-title-bar-color;
    color: @dialog-title-bar-font-color;
}

.ui-widget {
    font-size: 1em;
}

div#divPopup {
    background: @dialog-title-bar-color;
}

/******************************* REPORTS *************************************/

.report-links {
    position: relative;
}

.report-hr {
    position: absolute;
    margin: 0;
    left: 20px;
    bottom: 0;
    width: ~"calc(100% - 40px)";
}

.report-item ul {
    padding-left: 5px;
}

.report-item {
    min-height: 170px;
    position: relative;
    margin-bottom: 10px;
    display: flex;
}

.report-icon {
    height: 96px;
    width: 96px;
    margin-bottom: 50px;
    font-size: 96px;

    &::before {
        color: #00467f !important;
    }
}

.vertical-line {
    border-left: thin solid #ddd;
    position: absolute;
    left: 50%;
    margin-left: -1px;
    top: 0;
    height: 100%;
}

.report-row {
    display: flex;
    align-items: stretch;
}

@media only screen and (max-width : @navbar-breaking-point) {
    .vertical-line {
        height: 0;
    }

    .report-row {
        flex-direction: column;
    }

    .report-item {
        width: 100%;
    }
}

.report-performance-icon {
    margin-top: -7px;
}

.report-prompts-icon {
    margin-top: -7px;
}

.report-export-icon {
    margin-top: -7px;
}

.report-progress-icon {
    margin-top: -3px;
}

.report-usage-icon {
    margin-top: -2px;
}

/******************************* WRITING *************************************/

.writing-prompt-list-item {
    color: @writing-prompt-list-item;
}

.prompt-genre {
    color: @label-color;
}

/******************************* INPUTS *************************************/

textarea.form-control,
input.form-control,
select.form-control,
select.form-control + .chosen-container-multi ul.chosen-choices {
    border: 1px solid @input-border-color;
    border-radius: 2px;
}

/******************************* LESSONS *************************************/

.assign-lessons-dialog .next {
    .btn-primary
}

/******************************* STUDENT LIST TABS ***************************/

li > #mi-write-student {
    color: @alt-link-color;
    padding: 6px 12px;
}

li > #mi-write-groups {
    color: @alt-link-color;
    padding: 6px 12px;
}

.active > #mi-write-student {
    border: 1px solid;
    color: @btn-primary-color;
}

.active > #mi-write-groups {
    border: 1px solid;
    color: @btn-primary-color;
}

/******************************* DATE PICKER ***************************/

.ui-datepicker .ui-datepicker-header {
    background: @datepicker-bg;
    border: none;
    color: #555;
    font-size: 12px;
}

.ui-datepicker .ui-datepicker-prev.ui-state-hover,
.ui-datepicker .ui-datepicker-next.ui-state-hover {
    background: transparent;
    border: transparent;
    cursor: pointer;
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
    top: 2px;
}

.ui-datepicker .ui-icon.ui-icon-circle-triangle-w {
    background-image: url(../../Images/Common/chevron-white-left.svg);
    background-position: 3px;
    background-size: 10px;
}

.ui-datepicker .ui-icon.ui-icon-circle-triangle-e {
    background-image: url(../../Images/Common/chevron-white-right.svg);
    background-position: 3px;
    background-size: 10px;
}

/******************************* CONFIRM DIALOG ******************************/

.jconfirm-box .btn-confirm {
    background: @btn-primary-bg !important;
    border: 1px solid @btn-primary-border !important;
    color: @btn-primary-color !important;
}

.jconfirm-box .btn-cancel {
    background: @btn-default-bg !important;
    border: 1px solid @btn-default-border !important;
    color: @btn-default-color !important;
}

/******************************* ICONS AND IMAGES ***********************************/

.info-icon {
    color: @label-color;
    font-size: 20px;
}

#navbar-tabs img.mi-write-menu-icon,
#navbar-tabs span.micon {
    margin-right: 2px;
}